import React, { lazy, Suspense } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Loading from 'components/Loading'

import './App.scss'


const Home = lazy(() => import('pages/Home'))
const History = lazy(() => import('pages/History'))
const Blueprints=lazy(() => import('pages/Blueprints'))
const FrecuentQuestions=lazy(() => import('pages/FrecuentQuestions'))
const App= () => {
    return (
        <Router>
            <div className='App'>
                <Header />
                <div>
                    <Suspense fallback={<Loading />}>
                        <Switch>
                             <Route path='/History' >
                                <History />
                            </Route>
                            <Route path='/Blueprints' >
                                <Blueprints />
                            </Route>
                            <Route path='/FrecuentQuestions' >
                                <FrecuentQuestions />
                            </Route>
                            <Route path='/' default>
                                <Home />
                            </Route>                           
                        </Switch>
                    </Suspense>
                </div>
                <Footer />
            </div>
        </Router>
    )
}

export default App
