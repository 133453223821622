import React,{useState} from 'react'
import './styles.scss'
import { isMobile } from 'mobile-device-detect'
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import ModalContact from "../Contact"
const Menu= () => {
    const [modalShow, setModalShow] = useState(false);
const OpenConctact=()=>{
    setModalShow(true)
    console.log("contacto")
}
    return isMobile ? (
        <div>
        <Navbar collapseOnSelect expand="lg" className='navbar'>
            <Navbar.Brand href="#home">
                <img src='/assets/images/logo.png' alt='' className='logo'></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/Blueprints">Conoce el proyecto</Nav.Link>
                    <Nav.Link href="/History">Historia</Nav.Link>
                    <Nav.Link href="/FrecuentQuestions">Preguntas Frecuentes</Nav.Link>
                    <Nav.Link onClick={OpenConctact}>Contacto </Nav.Link>

                </Nav>
        
            </Navbar.Collapse>
        </Navbar>
            <ModalContact show={modalShow} onHide={() => setModalShow(false)} />
</div>

    ) : (
            <div className='menu'>
                   <Navbar collapseOnSelect expand="lg" className='navbar'>
            <Navbar.Brand href="#home">
                <img src='/assets/images/logo.png' alt='' className='logo'></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/Blueprints">Conoce el proyecto</Nav.Link>
                    <Nav.Link href="/History">Historia</Nav.Link>
                    <Nav.Link href="/FrecuentQuestions">Preguntas Frecuentes</Nav.Link>
                    <Nav.Link onClick={OpenConctact}>Contacto </Nav.Link>

                </Nav>
        
            </Navbar.Collapse>
        </Navbar>
        <ModalContact show={modalShow} onHide={() => setModalShow(false)} />

            </div>
        )
}

export default Menu
