import firebase from 'firebase/app'
import 'firebase/firestore'

export const config = {
    apiKey: "AIzaSyA7ZF_HzBBps-GzoVvCXgoFl8az4XDKbSk",
    authDomain: "remodeling-center-rancagua.firebaseapp.com",
    databaseURL: "https://remodeling-center-rancagua.firebaseio.com",
    projectId: "remodeling-center-rancagua",
    storageBucket: "remodeling-center-rancagua.appspot.com",
    messagingSenderId: "215931475672",
    appId: "1:215931475672:web:20a1536e127b8848ece9ad",
    measurementId: "G-998XY0N545"
  };

const app = firebase.initializeApp(config)
// export const firestore = firebase.firestore()

export default app
