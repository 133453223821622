

import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import styles from './Contact.module.scss'
import Iframe from 'react-iframe'

export default function ModalContact(props) {
    return (
        <Modal show={props.show} onHide={props.onHide} aria-labelledby='contained-modal-title-vcenter'>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter' className={styles.modalContactTitle}>
                    Formulario de Contacto
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.bodymodal}>
                <Iframe url="https://docs.google.com/forms/d/e/1FAIpQLSdELMMUd4dpASVJILZ-KY_kxAR0RMIw0OIMTbhPfz4pQQfd9A/viewform"
                    width="100%"
                    height="450px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative" />

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant='danger'>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
