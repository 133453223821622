import React from 'react'
import './styles.scss'

const Footer: React.FC = () => {
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col text-left'>@munirancagua</div>
                    <div className='col text-right'><a href="https://rancagua.cl" title='Rancagua'>www.rancagua.cl</a></div>
                </div>
                <div className='row'>
                    <div className='col text-left'>
                        <a href='https://www.facebook.com/munirancagua'><img src='/assets/images/social/icono_face.png' alt='Facebook' className='socialIcon'></img></a>
                        <a href='https://twitter.com/munirancagua'><img src='/assets/images/social/icono_twitter.png' alt='Twitter' className='socialIcon'></img></a>
                        <a href='https://www.instagram.com/munirancagua/?hl=es-la'><img src='/assets/images/social/icono_ig.png' alt='Instagram' className='socialIcon'></img></a>
                        <a href='https://www.youtube.com/user/munirancagua'><img src='/assets/images/social/icono_yt.png' alt='Youtube' className='socialIcon'></img></a>

                    </div>
                </div>
           
            </div>
            <div className='FooterCorporacion'>
            <div className='container'>
            <div className='row'>
                    <div className='col'>
                        <a href='mailto:contacto@smart.rancagua.cl'>&copy; desarrollo e innovación</a></div>
                </div>
                </div></div>

        </footer>
    )
}

export default Footer
