import React, { Fragment } from 'react'
import './styles.scss'
import { isMobile } from 'mobile-device-detect'
import Menu from '../Menu'

const Header = () => {
    const Goto = (url) => {
        console.log(url)

    }
    return isMobile ? (
        <Fragment>
            <div className='header'>
                <Menu></Menu>
            </div>

        </Fragment>
    ) : (
            <div className='container p-0'>
                <div className='header'>
                <Menu></Menu>
                </div>
            </div>
        )
}

export default Header
